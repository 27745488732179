import React from 'react';
import { Helmet } from 'react-helmet';

const CommApp = () => (
    <Helmet>
        <meta httpEquiv="refresh" content="0; URL='/methods/app'" />
        <link rel="canonical" href="/methods/app" />
    </Helmet>
);

export default CommApp;
